<template>
  <section class="userCredentials">
    <h2 class="userCredentials__title">Credenciales de usuario</h2>
    <ImgWithCroper
      :selectedImage="$store.state.user.dataUser.pictureUrl"
      :pictureName="$store.state.user.dataUser.fileName"
      :changePicture="changePicture"
    />
    <div class="userCredentials__contentInput">
      <label for="userName">Usuario *</label>
      <input
        class="userCredentials__input"
        type="text"
        id="userName"
        ref="userNameForm"
        placeholder="Nombre de usuario"
        v-model="$store.state.user.dataUser.user"
        @input="deleteWhiteSpace"
        @blur="validateString('userName', 'userNameForm', 'Usuario')"
      />
    </div>
    <span ref="userName" class="userCredentials__error"></span>
    <div class="userCredentials__contentInput">
      <label for="password">Contraseña *</label>
      <input
        class="userCredentials__input"
        type="password"
        id="password"
        ref="passwordForm"
        placeholder="Contraseña"
        v-model="$store.state.user.dataUser.password"
        @blur="validatePassword('password', 'passwordForm', 'la contraseña')"
      />
    </div>
    <span ref="password" class="userCredentials__error"></span>
    <div class="userCredentials__contentInput">
      <label for="passwordConfirm">Confirmar contraseña *</label>
      <input
        class="userCredentials__input"
        type="password"
        id="passwordConfirm"
        ref="passwordForm2"
        placeholder="Confirmar contraseña"
        v-model="$store.state.user.dataUser.repeatPassword"
        @blur="seePassword('password2', 'passwordForm2')"
      />
    </div>
    <span ref="password2" class="userCredentials__error"></span>
  </section>
</template>

<script>
import { formatString } from "@/js/validation.js";
export default {
  components: {
    ImgWithCroper: () => import("@/components/input/ImgWithCroper.vue"),
  },
  computed: {
    user() {
      return this.$store.state.user.dataUser;
    },
    isEditUser() {
      return !!this.user?._id;
    },
  },
  methods: {
    deleteWhiteSpace() {
      this.$store.state.user.dataUser.user = this.user.user.replace(/\s+/g, "");
    },
    changePicture({ picture = "", pictureUrl = null, fileName = "" }) {
      const user = this.$store.state.user.dataUser;

      user.picture = picture;
      user.pictureUrl = pictureUrl;
      user.fileName = fileName;
    },
    updateStyles(reference, referenceLocal, response) {
      reference.style.display = response ? "none" : "block";
      referenceLocal.style["border-color"] = response ? "#d8d8d8" : "#bd0909";
    },
    validateString(ref, refLocal, text, data) {
      const reference = this.$refs[ref];
      const referenceLocal = this.$refs[refLocal];
      const dataToSet = data ?? event?.srcElement ?? { value: "" };

      const response = formatString(reference, text, dataToSet);

      this.updateStyles(reference, referenceLocal, response);
      return response;
    },
    validatePassword(ref, refLocal, text, data) {
      const reference = this.$refs[ref];
      const referenceLocal = this.$refs[refLocal];
      const dataToSet = data ?? event?.srcElement?.value ?? "";

      const innerText = dataToSet === "" || dataToSet === "NA" ? `Ingrese ${text}` : "";
      const response = !innerText;

      reference.innerText = innerText;
      this.updateStyles(reference, referenceLocal, response);

      return response;
    },
    seePassword() {
      const { password, repeatPassword } = this.user;
      const ref = "password2";
      const refLocal = "passwordForm2";

      const isValid = this.validatePassword(ref, refLocal, "la contraseña", repeatPassword);

      if (!isValid) {
        return isValid;
      }

      const reference = this.$refs[ref];
      const referenceLocal = this.$refs[refLocal];

      const innerText = password !== repeatPassword ? "Las contraseñas no coinciden" : "";
      const response = !innerText;

      reference.innerText = innerText;
      this.updateStyles(reference, referenceLocal, response);

      return response;
    },
    verifyNext() {
      const { user, password } = this.user;
      const verifyUser = this.validateString("userName", "userNameForm", "Usuario", { value: user });
      const verifyPassword = this.validatePassword("password", "passwordForm", "la contraseña", password);
      const verifyPassword2 = this.seePassword();

      return verifyUser && verifyPassword && verifyPassword2;
    },
  },
  mounted() {
    if (this.isEditUser) {
      const { user } = this.user;
      this.validateString("userName", "userNameForm", "Usuario", user);
    }
  },
};
</script>

<style lang="scss">
.userCredentials {
  @include Flex(column, flex-start, flex-start);
  width: 100%;
  gap: $mpadding;
  &__title {
    font-weight: lighter;
    font-size: 24px;
  }
  &__contentInput {
    width: 100%;
  }
  &__input {
    width: 100%;
    height: 40px;
    border: 1px solid $alto;
    border-radius: 6px;
    padding: 5px;
    &:focus {
      outline: none;
    }
  }
  &__error {
    display: none;
    margin-top: -10px;
    font-weight: 600;
    font-size: 0.8em;
    color: $primary-color;
  }
}
</style>
